import { __decorate } from "tslib";
import { Component, Vue, Watch, Ref, Emit, } from 'vue-property-decorator';
import OrgTree from './org-tree.vue';
import OrgSearch from './org-search.vue';
import * as Utils from '@/common/utils';
import { OperateType } from '../../../../typings/organization/org-manage';
import AddDept from './add-dept.vue';
let OrgLeft = class OrgLeft extends Vue {
    constructor() {
        super(...arguments);
        this.keyword = '';
        this.operateType = OperateType.Add;
        this.showDeptModal = false;
        this.showSearch = false;
        this.isEnterSearch = false;
        this.deptDetail = null;
        this.debounce = function () { };
    }
    get isShowAddDeptBtn() {
        return this.keyword;
    }
    onKeyworkChange() {
        this.keyword = this.keyword.replace(/^\s*|\s*$/g, '');
        if (this.keyword) {
            this.isEnterSearch = false;
            this.debounce();
        }
        else {
            this.showSearch = false;
        }
    }
    created() {
        this.debounce = Utils.debounce(async () => {
            if (this.keyword) {
                this.showSearch = true;
            }
            else {
                this.showSearch = false;
                return;
            }
            if (this.isEnterSearch)
                return;
            await this.$nextTick();
            this.$refs.OrgSearch.search();
        }, 1000);
    }
    selectNode(item) {
        return item;
    }
    async search() {
        this.isEnterSearch = true;
        if (this.keyword) {
            this.showSearch = true;
            await this.$nextTick();
            this.$refs.OrgSearch.search();
        }
        else {
            this.$message.info('请输入搜索关键字');
        }
    }
    addChildDept(deptDetail) {
        this.operateType = OperateType.Add;
        this.deptDetail = deptDetail;
        this.showDeptModal = true;
    }
    addDept() {
        this.operateType = OperateType.Add;
        this.showDeptModal = true;
        this.deptDetail = null;
    }
    editDept(deptDetail) {
        this.operateType = OperateType.Edit;
        this.deptDetail = deptDetail;
        this.showDeptModal = true;
    }
    reloadTree(data) {
        const { type } = data;
        if (type === OperateType.Add) {
            this.OrgTree.reloadTreeByAddDepartment(data);
        }
        else if (type === OperateType.Edit) {
            this.OrgTree.reloadTreeByEditDepartment(data);
        }
    }
};
__decorate([
    Ref()
], OrgLeft.prototype, "searchComp", void 0);
__decorate([
    Ref()
], OrgLeft.prototype, "OrgTree", void 0);
__decorate([
    Watch('keyword')
], OrgLeft.prototype, "onKeyworkChange", null);
__decorate([
    Emit('selectNodeEvent')
], OrgLeft.prototype, "selectNode", null);
OrgLeft = __decorate([
    Component({
        name: 'OrgLeft',
        components: {
            OrgTree,
            OrgSearch,
            AddDept,
        },
    })
], OrgLeft);
export default OrgLeft;
