import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch, Ref, Emit, } from 'vue-property-decorator';
import { Form, FormItem } from '@/components/shared/CForm/index';
import { OrgManageService } from '@/sevices';
import { OperateType, UnitType, Strategy, } from '../../../../typings/organization/org-manage';
import * as Utils from '@/common/utils';
import { StaffSelector } from '@cloudpivot-hermes/common-components';
let addDept = class addDept extends Vue {
    constructor() {
        super(...arguments);
        this.visible = false;
        this.form = {
            name: '',
            parentDept: [],
        };
        this.OperateType = {
            Add: OperateType.Add,
            Edit: OperateType.Edit,
        };
        this.Strategy = Strategy;
        this.modalTitle = '添加子部门';
        this.submiting = false;
        this.loading = false;
        // 是否指定的父级部门添加子部门，指定部门时不能再更改部门
        this.isSelectedDept = false;
        this.rules = {
            name: [
                { required: true, message: '部门名称不能为空', trigger: 'blur' },
                {
                    validator: (value) => /^[\u4e00-\u9fa5_a-zA-Z0-9\-()]{0,20}$/.test(value),
                    message: '请输入中英文、数字及英文符号”()”、“-”、“_”，最大长度为20个字',
                    trigger: 'blur',
                },
            ],
            parentDept: [
                { required: true, message: '上级部门不能为空', trigger: 'change' },
            ],
        };
    }
    // 是否更改了父部门
    get isUpdateParentDept() {
        let result = false;
        if (!this.localParent) {
            result = true;
        }
        if (this.localParent?.id !== this.form.parentDept[0]?.id) {
            result = true;
        }
        return result;
    }
    // 只能选择自维护组织的部门作用上级部门
    // 编辑时上级部门要排除自身
    // 只能选择本组织的部门作用上级
    get staffSelectorParams() {
        return {
            strategy: Strategy.SelfMaintain,
            excludeDeptIds: this.form.id,
            orgId: this.deptDetail?.orgId,
            resourceCode: Strategy.OrgManageCode,
        };
    }
    onFormChange() {
        Utils.trim(this.form);
    }
    async comfirm() {
        const isValid = this.cform.validate();
        if (!isValid)
            return;
        try {
            this.submiting = true;
            this.operateType === OperateType.Add
                ? await this.addDept()
                : await this.updateDept();
        }
        finally {
            this.submiting = false;
        }
    }
    async getDeptDetail(id) {
        const res = await OrgManageService.getDeptDetail({ id: id });
        if (res.success) {
            return res.data;
        }
    }
    // 添加角色信息
    async addDept() {
        const params = {
            name: this.form.name,
            parentId: this.form.parentDept[0].id,
        };
        const addDeptRes = await OrgManageService.addDept(params);
        if (!addDeptRes.success)
            return;
        const addDeptData = addDeptRes.data;
        addDeptData.isRoot = false;
        addDeptData.isLeaf = true;
        this.$message.success('添加成功');
        this.reloadTree({
            data: addDeptData,
            type: OperateType.Add,
            isUpdateParentDept: this.isUpdateParentDept,
        });
        this.reload();
        this.cancel();
    }
    // 编辑部门信息
    async updateDept() {
        const params = {
            id: this.form.id,
            name: this.form.name,
            parentId: this.form.parentDept[0].id,
        };
        const updateDeptRes = await OrgManageService.updateDept(params);
        if (!updateDeptRes.success)
            return;
        const updateDeptData = updateDeptRes.data;
        this.$message.success('更新成功');
        this.reloadTree({
            data: updateDeptData,
            type: OperateType.Edit,
            isUpdateParentDept: this.isUpdateParentDept,
        });
        this.reload();
        this.cancel();
    }
    cancel() {
        this.$emit('input', false);
    }
    reload() { }
    reloadTree(data) {
        return data;
    }
    async onValueChange(v) {
        this.visible = v;
        if (!v) {
            await Utils.sleep(100);
            this.form = {
                name: '',
                parentDept: [],
            };
            this.isSelectedDept = false;
            this.cform.clearValidate();
            return;
        }
        let parentDept;
        if (this.operateType === OperateType.Edit) {
            this.modalTitle = '编辑部门';
            this.loading = true;
            const parentDeptDetail = await this.getDeptDetail(this.deptDetail.parentId);
            this.form = {
                id: this.deptDetail.id,
                name: this.deptDetail.name,
                parentDept: [{
                        id: parentDeptDetail.id,
                        name: parentDeptDetail.name,
                        type: UnitType.Dept,
                    }],
            };
            this.loading = false;
        }
        else {
            this.modalTitle = '添加子部门';
            if (this.deptDetail) {
                parentDept = {
                    id: this.deptDetail.id,
                    name: this.deptDetail.name,
                    type: UnitType.Dept,
                };
                this.isSelectedDept = true;
            }
            this.form = {
                name: '',
                parentDept: [],
            };
            if (parentDept) {
                this.form.parentDept.push(parentDept);
            }
        }
        try {
            this.localParent = JSON.parse(JSON.stringify(this.form.parentDept[0]));
        }
        catch (e) {
            // do nothing
        }
    }
};
__decorate([
    Prop()
], addDept.prototype, "value", void 0);
__decorate([
    Prop({ default: OperateType.Add })
], addDept.prototype, "operateType", void 0);
__decorate([
    Prop({
        default: () => {
            return {};
        },
    })
], addDept.prototype, "deptDetail", void 0);
__decorate([
    Ref()
], addDept.prototype, "cform", void 0);
__decorate([
    Watch('form', { deep: true })
], addDept.prototype, "onFormChange", null);
__decorate([
    Emit('reload')
], addDept.prototype, "reload", null);
__decorate([
    Emit('reloadTree')
], addDept.prototype, "reloadTree", null);
__decorate([
    Watch('value')
], addDept.prototype, "onValueChange", null);
addDept = __decorate([
    Component({
        name: 'addDept',
        components: {
            CForm: Form,
            CFormItem: FormItem,
            StaffSelector,
        },
    })
], addDept);
export default addDept;
