import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch, } from 'vue-property-decorator';
import { OrgManageService } from '@/sevices';
import { NodeType, SourceType } from '../../../../typings/organization/org-manage';
import * as Utils from '@/common/utils';
import { Empty } from '@h3/antd-vue';
import axios from 'axios';
const CancelToken = axios.CancelToken;
let cancel = null;
let OrgSearch = class OrgSearch extends Vue {
    constructor() {
        super(...arguments);
        this.searchList = [];
        this.selectedKeys = [];
        this.loading = false;
        this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        this.NodeType = {
            Org: NodeType.Org,
            Dept: NodeType.Dept,
        };
        this.SourceType = SourceType;
        this.pagination = {
            page: 1,
            size: 40,
        };
        this.onScrollSearchFn = () => { };
        this.isInitSearchList = false;
        this.nextPageSearching = false;
        // 搜索滚动性能优化
        this.searchContentHeight = 0;
        this.searchItemHeight = 0;
        this.total = 0;
    }
    get isNextPage() {
        return this.searchList.length !== this.total;
    }
    async onSearchListChange() {
        if (!this.searchList.length)
            return;
        // 以下是搜索性能优化
        if (!this.searchContentHeight) {
            await this.getSearchContentInfo();
        }
        if (this.isInitSearchList) {
            this.initScroll();
            this.isInitSearchList = false;
        }
    }
    async initScroll() {
        this.onScrollSearchMainAnimate({ target: { scrollTop: 0 } });
        this.onScrollSearchFn = Utils.throttle((event) => {
            this.onScrollSearchMain(event).finally(() => {
                this.onScrollSearchMainAnimate(event);
            });
            this.onScrollSearchMainAnimate(event);
        }, 100);
    }
    /**
     * 获取搜索项
     */
    async getSearchItemList() {
        await this.$nextTick();
        const searchItems = this.$refs.searchContent.querySelectorAll('.js-search-item');
        return searchItems;
    }
    /**
     * 获取搜索弹窗的信息
     */
    async getSearchContentInfo() {
        await this.$nextTick();
        const searchContent = this.$refs.searchContent;
        this.searchContentHeight = searchContent.clientHeight;
        const searchItems = await this.getSearchItemList();
        if (searchItems && searchItems.length) {
            this.searchItemHeight = searchItems[0]?.offsetHeight;
        }
    }
    /**
     *  触底加载条件：元素到顶部的距离 - 可见区域固定高度 <= 滚动条滚动高度
        触顶加载条件（距顶部显示条件）：元素到顶部的距离 + 5个元素的高度 >= 滚动条滚动高度
     */
    /**
     * 滚动事件的主体方法
     */
    async onScrollSearchMain(event) {
        if (this.nextPageSearching)
            return;
        const searchItems = await this.getSearchItemList();
        if (!searchItems.length)
            return;
        // 滚动条的滚动高度
        const scrollTop = event.target.scrollTop;
        const lastItemIndex = searchItems.length - 12;
        const bottomHeight = this.searchContentHeight + scrollTop;
        const lastItemTop = lastItemIndex * this.searchItemHeight;
        if (bottomHeight >= lastItemTop) {
            // 触底
            if (!this.isNextPage)
                return;
            this.nextPageSearching = true;
            this.pagination.page = this.pagination.page + 1;
        }
        if (this.nextPageSearching) {
            return this.scrollSearch().finally(() => {
                this.nextPageSearching = false;
            });
        }
    }
    /**
     *  触底加载条件：元素到顶部的距离 - 可见区域固定高度 <= 滚动条滚动高度
        触顶加载条件（距顶部显示条件）：元素到顶部的距离 + 5个元素的高度 >= 滚动条滚动高度
     */
    /**
     * 滚动事件动画
     */
    async onScrollSearchMainAnimate(e) {
        await this.$nextTick();
        const scrollTop = e.target.scrollTop;
        const searchItems = await this.getSearchItemList();
        searchItems.forEach((item, index) => {
            const itemOffsetTop = item.offsetTop;
            // 可见范围
            if (itemOffsetTop + this.searchItemHeight >= scrollTop &&
                itemOffsetTop - this.searchContentHeight <= scrollTop) {
                this.searchList[index].isShow = true;
            }
            else {
                this.searchList[index].isShow = false;
            }
        });
    }
    async selectNode(item) {
        this.selectSearch(item);
        this.$emit('selectNodeEvent', item);
        await this.selectTreeNode(item);
        const nodeState = this.$parent.OrgTree.getNodeStateById(item.id);
        if (!nodeState) {
            this.$message.error('组织树不存在当前选中的部门');
        }
    }
    selectSearch(item) {
        this.selectedKeys = [item.id];
    }
    async selectTreeNode(item) {
        await this.$parent.OrgTree.selectTreeNodeBySearch(item);
    }
    /**
     * 滚动事件
     */
    onScrollSearch(event) {
        if (!this.keyword)
            return;
        this.onScrollSearchFn(event);
    }
    /**
     * @description: 滚动加载搜索
     * @param {type} 组织树关键词
     */
    async scrollSearch() {
        if (!this.keyword)
            return;
        return this.getSearchData().then((res) => {
            this.handlerSearchList(res);
        });
    }
    /**
     * @description: 搜索
     * @param {type} 组织树关键词
     */
    search() {
        if (!this.keyword)
            return;
        this.loading = true;
        this.pagination.page = 1;
        this.getSearchData().then((res) => {
            this.searchList = [];
            this.isInitSearchList = true;
            this.handlerSearchList(res);
        }).finally(() => {
            this.loading = false;
        });
    }
    /**
     * 处理源数据
     * @param res
     */
    handlerSearchList(res) {
        if (res.success) {
            const data = res.data;
            const searchList = this.generateTree(data);
            this.searchList = [...this.searchList, ...searchList];
            this.total = res.total;
        }
    }
    /**
     * 调用搜索接口获取数据
     */
    getSearchData() {
        if (typeof cancel === 'function') {
            cancel();
        }
        const params = { name: this.keyword, ...this.pagination };
        const options = {
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            }),
        };
        return new Promise((resolve) => {
            OrgManageService.search(params, options).then((res) => {
                resolve(res);
                cancel = null;
            }).catch(() => { });
        });
    }
    generateTree(data) {
        const searchList = [];
        data.forEach((item) => {
            const orgTree = this.$parent.OrgTree;
            const children = undefined;
            const isRoot = undefined;
            const pos = undefined;
            const namePath = undefined;
            const isSearch = true;
            const node = orgTree.generateOrgNode(item, children, isRoot, pos, namePath, isSearch);
            searchList.push(node);
        });
        return searchList;
    }
};
__decorate([
    Prop()
], OrgSearch.prototype, "keyword", void 0);
__decorate([
    Watch('searchList', { immediate: true })
], OrgSearch.prototype, "onSearchListChange", null);
OrgSearch = __decorate([
    Component({
        name: 'OrgSearch',
    })
], OrgSearch);
export default OrgSearch;
