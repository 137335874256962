import { __decorate } from "tslib";
import { Component, Vue, Ref } from 'vue-property-decorator';
import { OrgManageService } from '@/sevices';
import { NodeType, SourceType, } from '../../../../typings/organization/org-manage';
import TreeUtils from '@/common/TreeUtils';
import { Empty } from '@h3/antd-vue';
import * as Utils from '@/common/utils';
let OrgTree = class OrgTree extends Vue {
    constructor() {
        super(...arguments);
        // 组织树
        this.orgTree = [];
        // 展开的节点key值
        this.expandedKeys = [];
        // 选中的节点状态key值
        this.selectedKeys = [];
        this.treeIdPosMap = {};
        this.childrenIdMap = {};
        // 当前树的位置信息
        this.nodePos = '';
        this.curNodeStateId = '';
        this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        this.treeLoading = false;
        this.NodeType = {
            Org: NodeType.Org,
            Dept: NodeType.Dept,
        };
        this.SourceType = SourceType;
        this.onScrollTreeFn = () => { };
        // 滚动性能优化
        this.treeContentHeight = 0;
    }
    // 已加载的节点key值
    get loadedKeys() {
        return this.expandedKeys;
    }
    get canAction() {
        return this.curNodeState && !this.curNodeState.isRoot;
    }
    async created() {
        await this.init();
        await this.getTreeContentInfo();
        this.initScroll();
    }
    /**
     * 获取弹窗的信息
     */
    async getTreeContentInfo() {
        await this.$nextTick();
        const orgTreeDom = this.$refs.orgTreeDom;
        this.treeContentHeight = orgTreeDom.clientHeight;
    }
    /**
     *  触底加载条件：元素到顶部的距离 - 可见区域固定高度 <= 滚动条滚动高度
        触顶加载条件（距顶部显示条件）：元素到顶部的距离 + 5个元素的高度 >= 滚动条滚动高度
     */
    /**
     * 滚动事件的主体方法
     */
    async onScrollTreeMain() {
        await this.$nextTick();
        const treeItems = this.orgTreeDom.querySelectorAll('.trigger-scroll-element');
        const orgTreeBound = this.orgTreeDom.getBoundingClientRect();
        for (let i = 0, len = treeItems.length; i < len; i++) {
            const item = treeItems[i];
            const itemBound = item.getBoundingClientRect();
            const itemOffsetTop = itemBound.top - orgTreeBound.top;
            const orgTreeOffsetHeight = orgTreeBound.height;
            // 触底
            if (itemOffsetTop - 100 <= orgTreeOffsetHeight &&
                !item.dataset.isload) {
                const parentId = item.dataset.parentid;
                const parentNode = this.getNodeStateById(parentId);
                const currentPageNum = parentNode.pagination.pageNum;
                const start = currentPageNum * parentNode.pagination.pageSize;
                parentNode.pagination.pageNum += 1;
                const nextPageNum = parentNode.pagination.pageNum;
                const end = nextPageNum * parentNode.pagination.pageSize;
                const moreChildren = this.childrenIdMap[parentId]?.slice(start, end);
                if (moreChildren) {
                    parentNode.children = [...parentNode.children, ...moreChildren];
                }
                item.setAttribute('data-isload', 'true');
                // console.log('触底');
            }
        }
    }
    async initScroll() {
        this.onScrollTreeFn = Utils.throttle(() => {
            this.onScrollTreeMain();
        }, 100);
    }
    /**
     * 滚动事件
     */
    onScrollTree(event) {
        this.onScrollTreeFn(event);
    }
    /**
     * 初始化树组件
     */
    async init() {
        this.treeLoading = true;
        this.getOrgNodes().then(() => {
            // 默认选中第一个组织部门
            if (this.orgTree && this.orgTree.length > 0) {
                const curNodeState = this.orgTree[0];
                if (this.orgTree.length === 1) {
                    this.expandedKeys = [curNodeState.id];
                }
                const nodePos = '0-0';
                this.mockSelectNode(curNodeState, nodePos);
            }
        }).finally(() => {
            this.treeLoading = false;
        });
    }
    /**
     * 展开组织树节点
     */
    async expandTree(expandedKeys, e) {
        await this.loadChildrenNodes(e);
        const { expanded, node: { dataRef } } = e;
        if (expanded) {
            this.pushExpandKey(dataRef.id);
        }
        else {
            this.deleteExpandKey(dataRef.id);
        }
    }
    /**
     * 收起节点
     * @param node
     */
    async deleteExpandKey(id) {
        const index = this.expandedKeys.findIndex(key => key === id);
        if (index >= 0) {
            this.expandedKeys.splice(index, 1);
        }
    }
    /**
     * 展开节点
     * @param node
     */
    pushExpandKey(id) {
        const index = this.expandedKeys.findIndex(key => key === id);
        if (index < 0) {
            this.expandedKeys.push(id);
        }
    }
    /**
     * 加载子节点
     */
    async loadChildrenNodes(e) {
        // 已展开的节点不再请求
        const { expanded, node: { dataRef, pos } } = e;
        if (!expanded)
            return;
        if (dataRef.children && dataRef.children.length)
            return;
        const deptId = dataRef.id;
        const param = {
            parentId: deptId,
        };
        this.$set(dataRef, 'expandLoading', true);
        await this.getOrgNodes(param, pos);
        dataRef.expandLoading = false;
    }
    /**
     * 点击组织树节点
     */
    async selectNode(selectedKeys, e, isUpdateDept = false) {
        // 如果是当前部门，再单击显示下拉信息，则不让它执行单击程序
        if (e.node.dataRef.id === this.curNodeStateId && e.node.dataRef.isClickDropDownBtn && !isUpdateDept)
            return;
        // 当前节点信息
        this.curNodeStateId = e.node.dataRef.id;
        this.curNodeState = e.node.dataRef;
        this.selectedKeys = [e.node.dataRef.id];
        this.nodePos = e.node.pos;
        const curNodeState = this.curNodeState;
        const selectedNode = {
            id: curNodeState.id,
            name: curNodeState.name,
            nodeType: curNodeState.nodeType,
            sourceType: curNodeState.sourceType,
            relatedSyncType: curNodeState.relatedSyncType,
            parentId: curNodeState.parentId,
            orgId: curNodeState.orgId,
            organizationName: curNodeState.organizationName,
            namePath: curNodeState.namePath,
            isEdit: curNodeState.isEdit,
            level: curNodeState.level,
        };
        this.$emit('selectNodeEvent', selectedNode);
    }
    // 加载组织树源数据
    async getOrgNodes(params, nodePos) {
        const res = await OrgManageService.childrens(params);
        const data = res.data;
        if (!res.success) {
            this.$message.error(res.errMessage);
            return;
        }
        this.renderOrgTree(data, nodePos);
    }
    // 根据节点位置判断是更新原有组织树还是初次生成组织树
    renderOrgTree(originalTreeData, nodePos) {
        if (nodePos) {
            this.updateOrgTreeByPos(originalTreeData, nodePos);
        }
        else {
            const parentId = '';
            const isRoot = true;
            const pos = [0];
            const namePath = [];
            this.orgTree = this.generateOrgTree(originalTreeData, parentId, isRoot, pos, namePath);
        }
    }
    // 通过节点位置更新原有组织树
    updateOrgTreeByPos(originalTreeData, nodePos) {
        const pos = nodePos.split('-').slice(1);
        let resultNode;
        let orgTree = this.orgTree;
        for (let i = 0, len = pos.length; i < len; i++) {
            resultNode = orgTree[Number(pos[i])];
            if (i < len) {
                orgTree = resultNode.children;
            }
        }
        const childPos = nodePos.split('-').map((item) => Number(item));
        const parentId = resultNode.id;
        const isRoot = false;
        const namePath = resultNode.namePath?.split('/');
        const children = this.generateOrgTree(originalTreeData, parentId, isRoot, childPos, namePath);
        if (children.length >= 40) {
            this.childrenIdMap[parentId] = children;
        }
        resultNode.children = children.slice(0, 40);
    }
    /**
     * 生成组织树
     */
    generateOrgTree(originalTreeData, parentId, isRoot, pos = [], namePath = []) {
        const tree = [];
        if (!Array.isArray(originalTreeData))
            return tree;
        for (let i = 0, len = originalTreeData.length; i < len; i++) {
            const item = originalTreeData[i];
            const itemNamePath = [...namePath, item.name];
            let children = [];
            const nodePos = [...pos];
            nodePos.push(i);
            // 默认第一层树有children则渲染children
            if (item.children && item.children.length) {
                children = this.generateOrgTree(item.children, item.id, false, nodePos, itemNamePath);
            }
            item.parentId = item.parentId ? item.parentId : parentId;
            const node = this.generateOrgNode(item, children, isRoot, nodePos, itemNamePath);
            tree.push(node);
        }
        return tree;
    }
    /**
     * 生成组织树的节点
     */
    generateOrgNode(originalData, children = [], isRoot = false, pos = [], namePath = [], isSearch = false) {
        const nodePos = pos.join('-');
        if (!isSearch) {
            this.treeIdPosMap[originalData.id] = nodePos;
        }
        const node = {
            key: originalData.id,
            title: originalData.name,
            children: children,
            isLeaf: originalData.isLeaf,
            scopedSlots: {
                title: 'title',
            },
            showDropDown: false,
            isShow: false,
            isClickDropDownBtn: false,
            isEdit: false,
            isRoot,
            pos: nodePos,
            index: pos[pos.length - 1],
            pagination: {
                pageSize: 40,
                pageNum: 1,
            },
            // 组织、部门数据
            id: originalData.id,
            name: originalData.name,
            namePath: namePath.join('/'),
            parentId: originalData.parentId,
            orgId: originalData.orgId,
            organizationName: originalData.organizationName,
            level: originalData.level,
            hasChild: !originalData.isLeaf,
            nodeType: originalData.isRoot ? NodeType.Org : NodeType.Dept,
            relatedSyncType: originalData.sourceType === SourceType.SelfMaintain ? 'PUSH' : 'PULL',
            sourceType: originalData.sourceType,
        };
        return node;
    }
    /*
     * 重载树
     */
    async reloadTree() {
        this.init();
    }
    /**
     * 添加部门时重载树
     */
    async reloadTreeByAddDepartment(res) {
        const { isUpdateParentDept, data } = res;
        // 接口未返回，补全数据
        data.isRoot = false;
        data.sourceType = SourceType.SelfMaintain;
        if (isUpdateParentDept) {
            // 上级部门已改变
            this.addDepartmentByChangeParent(data);
        }
        else {
            // 上级部门未改变
            this.addDepartmentByNotChangeParent(data);
        }
    }
    /**
     * 编辑部门时重载树
     */
    async reloadTreeByEditDepartment(res) {
        const { isUpdateParentDept, data } = res;
        // 接口未返回，补全数据
        data.isRoot = false;
        data.sourceType = SourceType.SelfMaintain;
        if (isUpdateParentDept) {
            // 上级部门已改变
            this.editDepartmentByChangeParent(data);
        }
        else {
            // 上级部门未改变
            this.editDepartmentByNotChangeParent(data);
        }
    }
    /**
     * 删除部门时重载树
     */
    async reloadTreeByDelDepartment() {
        // 获取父级部门
        const parentPos = this.nodePos.substring(0, this.nodePos.lastIndexOf('-'));
        const parentNodeState = TreeUtils.getTreeNodeStateByPos(this.orgTree, parentPos);
        // 从组织树中移除指定的部门
        this.deleteDepartmentKey(parentNodeState);
        this.mockSelectNode(parentNodeState, parentPos);
    }
    /**
     * 编辑已改变父级部门的部门
     */
    async editDepartmentByChangeParent(data) {
        // 获取父级部门
        const nodePos = this.nodePos.substring(0, this.nodePos.lastIndexOf('-'));
        const parentNodeState = TreeUtils.getTreeNodeStateByPos(this.orgTree, nodePos);
        // 从组织树中移除指定的部门
        this.deleteDepartmentKey(parentNodeState);
        // 转移编辑部门的层层父级部门到指定部门
        await this.moveParentDepartment(data);
        // 获取被编辑的部门信息
        const nodeState = this.getNodeStateById(data.id);
        // 关闭当前部门的展开状态
        const index = this.expandedKeys.findIndex((id) => id === data.id);
        if (index >= 0)
            this.expandedKeys.splice(index, 1);
        // 编辑时不需要加载人员列表页
        nodeState.isEdit = true;
        // 模拟单击选中部门
        this.mockSelectNode(nodeState, nodeState.pos, true);
        // 重置
        nodeState.isEdit = false;
    }
    /**
     * 编辑未改变父级部门的部门
     */
    editDepartmentByNotChangeParent(data) {
        const curNode = TreeUtils.getTreeNodeStateByPos(this.orgTree, this.nodePos);
        curNode.name = data.name;
        curNode.title = data.name;
        // 编辑时不需要加载人员列表页
        curNode.isEdit = true;
        // 模拟单击选中部门
        this.mockSelectNode(curNode, this.nodePos, true);
        // 重置
        curNode.isEdit = false;
    }
    /**
     * 渲染新增的部门所在的组织树（上级部门已改变）
     */
    async addDepartmentByChangeParent(data) {
        await this.moveParentDepartment(data);
        // 获取新增部门的信息
        const nodeState = this.getNodeStateById(data.id);
        // 模拟单击部门事件对象
        this.mockSelectNode(nodeState, nodeState.pos);
    }
    /**
     * 上级部门已改变
     */
    addDepartmentByPushParentDept() { }
    // 追加新增的部门到组织树（上级部门未改变）
    async addDepartmentByNotChangeParent(data) {
        const node = this.generateOrgNode(data);
        const parentId = data.parentId;
        // 通过parentId获取父级部门信息
        const parentNodeState = this.getNodeStateById(parentId);
        if (!parentNodeState)
            return;
        if (parentNodeState.children && parentNodeState.children.length) {
            /** *****如果父部门已经展开过的情况下，则往父部门追加新增的部门*****/
            // 自动向父级部门追加新增的部门
            parentNodeState.children.push(node);
            // 如果没有展开,则展开父级 部门
            if (!this.expandedKeys.includes(parentId)) {
                this.expandedKeys.push(parentId);
            }
        }
        else {
            /** ****如果父部门未展开过的情况下，则模拟展开父级部门，并加载父级部门的所有子部门******/
            // 如果父级部门还没有子部门的情况下，则更新父级部门信息
            if (parentNodeState.isLeaf)
                parentNodeState.isLeaf = false;
            const expandEvent = {
                expanded: true,
                node: {
                    dataRef: parentNodeState,
                    pos: parentNodeState.pos,
                },
            };
            this.expandedKeys.push(parentId);
            await this.loadChildrenNodes(expandEvent);
        }
        // 展开所有父级部门
        this.expandParentDepartment();
        /** ***模拟单击选中新增的部门******/
        // 自动获取新增部门的位置信息
        const treeNode = parentNodeState.children.find(item => item.id === data.id);
        const newNodePos = treeNode.pos;
        // 模拟单击部门事件对象
        this.mockSelectNode(node, newNodePos);
    }
    /**
     * 展开所有父级部门
     */
    expandParentDepartment() {
        const pos = this.nodePos.split('-').slice(1);
        let orgTree = this.orgTree;
        for (let i = 0, len = pos.length; i < len; i++) {
            const orgTreeObject = orgTree[Number(pos[i])];
            if (i < len) {
                orgTree = orgTreeObject.children;
                const nodeId = orgTreeObject.id;
                if (!this.expandedKeys.includes(nodeId))
                    this.expandedKeys.push(nodeId);
            }
        }
    }
    /**
     * 转移新增或编辑部门的层层父级部门到指定部门
     */
    async moveParentDepartment(data) {
        // 获取新增部门所在树的所有父级部门
        const allParentTreeOriginalData = await this.getAllParentTree(data.id);
        if (!allParentTreeOriginalData)
            return;
        // 生成组织树
        const pId = '';
        const isRoot = true;
        const pos = [0];
        const allParentOrgTree = this.generateOrgTree(allParentTreeOriginalData, pId, isRoot, pos);
        return this.reRenderTree(allParentOrgTree, data.id);
    }
    /**
     * 重新渲染树
     */
    reRenderTree(orgTree, targetId) {
        let nodeState;
        TreeUtils.forEachTree(orgTree, (item) => {
            if (item.id === targetId) {
                nodeState = item;
            }
            // 循环的节点没有子节点，则不处理
            if (!item.children.length)
                return;
            // 如果有子节点，则当前树中找到当前循环节点
            const treeNodeState = this.getNodeStateById(item.id);
            // 如果循环的节点的子节点和树中的节点的子节点个数不配置，则把循环的子节点赋予树节点的子节点
            if (treeNodeState.children.length !== item.children.length) {
                treeNodeState.children = item.children;
            }
            // 如果循环的节点有子节点
            if (treeNodeState.children.length) {
                treeNodeState.isLeaf = false;
            }
            if (!this.expandedKeys.includes(item.id)) {
                this.expandedKeys.push(item.id);
            }
        });
        return nodeState;
    }
    /**
     * 获取子节点
     */
    getChildrenNode(tree) {
        let node;
        // 找到同级，同时拥有子部分的节点
        for (let i = 0, len = tree.length; i < len; i++) {
            const item = tree[i];
            const children = item.children;
            if (children && children.length) {
                node = item;
                break;
            }
        }
        return node;
    }
    /**
     * 移除部门Key
     */
    deleteDepartmentKey(parentNodeState) {
        // 移除已删除的部门
        const deletedNodePos = Number(this.nodePos.split('-').pop());
        parentNodeState.children.splice(deletedNodePos, 1);
        // 如果父级部门没有子部门，则父级部门变成叶子
        if (!parentNodeState.children.length) {
            const parentExpandedKeyIndex = this.expandedKeys.findIndex((item) => item.id === parentNodeState.id);
            this.expandedKeys.splice(parentExpandedKeyIndex, 1);
            parentNodeState.isLeaf = true;
        }
    }
    /**
     * 通过ID获取节点
     * @param id
     * @returns
     */
    getNodeStateById(id) {
        const nodePos = this.treeIdPosMap[id];
        if (!nodePos) {
            return;
        }
        const nodeState = TreeUtils.getTreeNodeStateByPos(this.orgTree, nodePos);
        return nodeState;
    }
    /**
     * 获取节点位置获取层层节点id
     */
    getKeyByPos(pos) {
        const nodePos = pos.split('-');
        // 去掉头部不需要的
        nodePos.shift();
        // 去掉节点自己
        nodePos.pop();
        let treeNode;
        let orgTree = this.orgTree;
        const result = [];
        for (const item of nodePos) {
            treeNode = orgTree[item];
            result.push(treeNode.id);
            const children = treeNode.children || [];
            if (children && children.length) {
                orgTree = children;
            }
        }
        return result;
    }
    /**
     * 展开从搜索选中的节点
     */
    async selectTreeNodeBySearch(item) {
        let nodeState = this.getNodeStateById(item.id);
        if (nodeState) {
            const keys = this.getKeyByPos(nodeState.pos);
            this.expandedKeys = [...new Set([...this.expandedKeys, ...keys])];
            this.selectedKeys = [item.id];
        }
        else {
            nodeState = (await this.moveParentDepartment(item));
            this.selectedKeys = [item.id];
        }
        if (nodeState) {
            this.setTreeScrollTop(nodeState, this.orgTreeDom);
        }
        else {
            this.$message.error('组织树中没有选中的节点数据');
        }
    }
    /**
     *  触底加载条件：元素到顶部的距离 - 可见区域固定高度 <= 滚动条滚动高度
        触顶加载条件（距顶部显示条件）：元素到顶部的距离 + 5个元素的高度 >= 滚动条滚动高度
     */
    /**
     * 自动滚动到可见范围
     */
    async setTreeScrollTop(node, treeDom) {
        await this.$nextTick();
        const scrollTop = treeDom.scrollTop;
        const treeDomBound = treeDom.getBoundingClientRect();
        const itemHtml = TreeUtils.getItemHtmlByPos(node.pos, treeDom);
        const li = TreeUtils.getParentItemHtml(itemHtml, 'li');
        const liBound = li.getBoundingClientRect();
        const itemOffsetTop = liBound.top - treeDomBound.top;
        const treeContentHeight = treeDomBound.height;
        // 不可见范围
        if (itemOffsetTop < scrollTop ||
            itemOffsetTop - treeContentHeight > scrollTop) {
            treeDom.scrollTop = itemOffsetTop - treeContentHeight * 0.5 - 16;
        }
    }
    /**
     * 模拟单击选中部门
     */
    mockSelectNode(nodeState, nodePos, isUpdateDept = false) {
        // 模拟单击选中父级部门
        const selectEvent = {
            node: {
                dataRef: nodeState,
                pos: nodePos,
            },
        };
        const expandedKeys = [nodeState.id];
        this.selectNode(expandedKeys, selectEvent, isUpdateDept);
    }
    /**
     * 通过id获取层层父级部门
     */
    async getAllParentTree(deptId) {
        const res = await OrgManageService.getAllParentTree({ parentId: deptId });
        if (!res.success) {
            this.$message.error(res.errMessage);
            return;
        }
        return res.data;
    }
    async visibleChange(record) {
        const dataRef = record.dataRef;
        dataRef.isClickDropDownBtn = true;
        dataRef.showDropDown = !dataRef.showDropDown;
        await Utils.sleep(1000);
        dataRef.isClickDropDownBtn = false;
    }
    /**
     * 显示添加子部门弹窗
     */
    addChildDepartment(record) {
        const dataRef = record.dataRef;
        dataRef.showDropDown = !dataRef.showDropDown;
        const addDeptDetail = {
            id: dataRef.id,
            name: dataRef.name,
            parentId: dataRef.parentId,
            orgId: dataRef.orgId,
        };
        this.$parent.addChildDept(addDeptDetail);
    }
    /**
     * 显示编辑部门弹窗
     */
    editDepartment(record) {
        const dataRef = record.dataRef;
        dataRef.showDropDown = !dataRef.showDropDown;
        const editDeptDetail = {
            id: dataRef.id,
            name: dataRef.name,
            parentId: dataRef.parentId,
            orgId: dataRef.orgId,
        };
        this.$parent.editDept(editDeptDetail);
    }
    /**
     * 删除部门
     */
    deleteDepartment(item) {
        const dataRef = item.dataRef;
        dataRef.showDropDown = !dataRef.showDropDown;
        this.$confirm({
            title: '确定删除此部门吗？',
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    this.handleDeleteDepartment(dataRef).then(() => {
                        resolve(null);
                    }).catch((e) => {
                        reject(e);
                    });
                });
            },
        });
    }
    /**
     * 调用删除接口
     */
    async handleDeleteDepartment(item) {
        const params = { id: item.id };
        const res = await OrgManageService.deleteDepartment(params);
        if (res.success) {
            this.$message.success('删除部门成功！');
            this.reloadTreeByDelDepartment();
        }
        else {
            this.$message.error(res.errMessage || '无法删除');
        }
    }
    mouseEnter(e, eData) {
        const { dataRef } = eData;
        if (typeof dataRef.isMouseOver === 'boolean') {
            dataRef.isMouseOver = true;
        }
        else {
            this.$set(dataRef, 'isMouseOver', true);
        }
    }
    mouseLeave(e, eData) {
        const { dataRef } = eData;
        dataRef.isMouseOver = false;
    }
};
__decorate([
    Ref()
], OrgTree.prototype, "orgTreeDom", void 0);
OrgTree = __decorate([
    Component({
        name: 'OrgTree',
        components: {
            Avatar: () => import('../avatar.vue'),
        },
    })
], OrgTree);
export default OrgTree;
